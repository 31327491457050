$(document).ready(function() {
    setTimeout(function(){
        $('.img-trans img').css('opacity', '0.75');
    }, 500);

    $( ".burger" ).click(function() {
        $('nav').css('display', 'block');
    });

    $('.single-item').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        // Image
        $('.nav-elem-active').removeClass('nav-elem-active');
        $('#men-'+nextSlide).addClass('nav-elem-active');

        $('.img-trans img').css('opacity', '1');

        // Image transparent
        setTimeout(function(){
            $('.img-trans img').css('opacity', '0.75');
            }, 1000);

        // Line
        $('.line').removeClass('line-active');
        $('.line-'+nextSlide).addClass('line-active');

        // Link actif
        // $('.link').css('display', 'none');
        // $('.link-'+nextSlide).css('display', 'block');

        // location.href

        if(nextSlide==1){
            setTimeout(function(){
                $('.img-trans img').addClass('more-white');
            }, 1000);

        }else{
            $('.img-trans img').removeClass('more-white');

        }

        setTimeout(function(){
            location.href=$('.slide-'+(nextSlide+1)).data('value');
        }, 1500);
    });
});